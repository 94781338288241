@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html {
  font-size: 62.5%;
  font-family: Roboto Mono, Arial;
  
}


.App {
  margin: 0 auto;
  width: 100vw;
  min-height: 100vh;
  max-width: 144rem;
  padding: 2rem;
  background: #FFFEF2;
  padding-bottom: 2rem;
  color: #0F228D;;
}

.App .darkmode  {
  background: #0F228D;
  color: white;
}


nav {
  margin: 0 auto;
  margin-bottom: 1rem;
  max-width: 90vw;
}

nav ul {
  list-style-type: none;
  display:flex;
  justify-content: space-between;

}

.menu-links {
  font-size: 2rem;
  font-weight: bold;
}



.menu-links a {
  color: #0F228D;
  text-decoration: none;
  transition: all .5s ease-in-out;
}




#menu-link-work {
  right: 3%;
}

#menu-link-about {
  bottom: 3%;
}

#menu-link-contact {
  bottom: 3%;
  right: 3%;
}

.Home, .Bio, .Work, .dopo, .sunnei {
  max-width: 90vw;
  margin: 0 auto;
}

.Home, .Bio{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}


.Home {
  margin-top: 25rem;
}


.Home h1 {
 font-size: 5.5rem;
  text-align: center;

}


.Bio {
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.bio-content {
  margin-top: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}

.bio-content img {
  max-width: 30rem;
  min-width: 25rem;
  height: 100%;
}

.bio-content span {
  font-weight: bold;
}

.bio-content p {
  max-width: 50rem;
  font-size: 1.5rem;
  /* text-align: justify; */
}

/* .bio-content p::-webkit-scrollbar { 
  display: none;
} */



.bio-content a {
  font-style: italic;
  color: #0F228D;
  text-decoration: none;
  font-weight: bold;
}

.bio-content a:hover {
  color: greenyellow;
}

.Work {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}


.Work h1 {
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  margin-bottom: .5rem;
}


.Work ul {
  margin-left: 1.5rem;
}


.Work h3,
.Work a {
  font-size: 1.5rem;
 margin-bottom: .4rem;
 font-weight: normal;
}

.Work a {
  color: rgb(63, 63, 63);
  text-decoration: none;
}


.Work a:hover {
  color: greenyellow;
}


.selectedWriting-wrapper  {
 margin-bottom: 1rem;
}

.dopo, .sunnei {
  margin-top: 7rem;
  display: flex;
 flex-direction: column;
}

.dopo img, 
.sunnei img {
  width: 60rem;
  max-width: 90%;
  margin-bottom: 2rem;
}

.dopo p,
.sunnei p {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  max-width: 50rem;
}

@media (max-width: 700px) {

  .bio-content {
  flex-direction: column;
  align-items: center;
  }


  .bio-content img {
    width: 30%;
  }

  .bio-content p {
 font-size: 1.4rem;
  text-align: center;
max-width: 90%;
  }

  .dopo, .sunnei{
    align-items: center;
  }
  .dopo p, .sunnei p {
    width: 90%;
    text-align: center;
  }

.Home {
  margin-top: 15rem;
}

}

